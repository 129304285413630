
  import { getRankListApi } from "@/api/h5"
  import dayjs from 'dayjs'
  export default {
    name: 'RankingIndex',
    data() {
      return {
        tabName: '-1',
        dateInfo: {
          show: false,
          value: new Date(),
          minDate: new Date(2024, 0, 1)
        },

        topThreeList: [],
        otherList: []
      }
    },
    computed: {
    },
    filters: {
      formatDate(val) {
        return dayjs(val, 'YYYY-MM').format('YYYY年MM月')
      }
    },
    created() {
      this.getRankList()
    },
    methods: {
      dayjs,
      getRankList() {
        let params = {
          series: this.tabName,
          month: this.dayjs(this.dateInfo.value).format('YYYY-MM'),
          userId: this.$route.query.userId
        }
        this.$store.dispatch('base/SetLoading', true)
        getRankListApi(params).then(res => {
          if(res.data.code === 200) {
            console.log(res.data.result, 'result')
            this.topThreeList = res.data.result.qianSanList || []
            this.otherList = res.data.result.xiaList || []
          }
        }).catch(() => {
          this.$toast('系统开小差')
        }).finally(() => {
          this.$store.dispatch('base/SetLoading', false)
        })
      },
      handleTabChange(name) {
        this.tabName = name
        this.getRankList()
      },
      handleDateShow() {
        this.dateInfo.show = true
      },
      handleDateConfirm(date) {
        this.dateInfo.value = date
        this.dateInfo.show = false
        this.getRankList()
      },
      handleDateCancel() {
        this.dateInfo.show = false
      },
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`
        } else if (type === 'month') {
          return `${val}月`
        }
        return val
      }
    }
  }
